/* responsiveTable.css */

/* การแสดงผลปกติ */
[data-label]:before {
  content: attr(data-label);
  display: none;
  /* ซ่อนค่า data-label */
}


@media screen and (max-width: 375px) {
  .floating-buttons {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: 1000;
    transition: opacity 0.3s ease, visibility 0.3s ease;
  }

  .floating-buttons.hidden {
    opacity: 0;
    visibility: hidden;
  }

  .floating-buttons.visible {
    opacity: 1;
    visibility: visible;
  }

  .floating-buttons button {
    background-color: #1890ff;
    color: white;
    border: none;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.973);
    cursor: pointer;
  }

  .floating-buttons button:hover {
    background-color: #40a9ff;
  }

  table {
    border: 0;
  }

  table caption {
    font-size: 1.3em;
  }

  .responsive-table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table td:last-child {
    border-bottom: 0;
  }
}

@media screen and (max-width: 600px) {
  .floating-buttons {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: 1000;
    transition: opacity 0.3s ease, visibility 0.3s ease;
  }

  .floating-buttons.hidden {
    opacity: 0;
    visibility: hidden;
  }

  .floating-buttons.visible {
    opacity: 1;
    visibility: visible;
  }

  .floating-buttons button {
    background-color: #1890ff;
    color: white;
    border: none;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }

  .floating-buttons button:hover {
    background-color: #40a9ff;
  }






  table {
    border: 0;
  }

  table caption {
    font-size: 1.3em;
  }

  .responsive-table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table td:last-child {
    border-bottom: 0;
  }
}

.input-button-group {
  display: flex;
  align-items: center;
  gap: 8px;
}

/* responsiveTable.css */
@media screen and (max-width: 768px) {

  [data-label]:before {
    content: attr(data-label);
    /* ดึงค่า data-label มาแสดง */
    display: block;
    /* แสดงค่า data-label */
    font-weight: bold;
    /* เพิ่มตัวหนาให้หัวข้อ */
    margin-bottom: 4px;
    /* เพิ่มระยะห่าง */
  }

  [data-label] {
    display: block;
    /* ทำให้ข้อมูลจัดเรียงในแนวตั้ง */
    text-align: left;
    /* จัดตำแหน่งข้อความไปทางซ้าย */
  }

  .floating-buttons {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: 1000;
    transition: opacity 0.3s ease, visibility 0.3s ease;
  }

  .floating-buttons.hidden {
    opacity: 0;
    visibility: hidden;
  }

  .floating-buttons.visible {
    opacity: 1;
    visibility: visible;
  }

  .floating-buttons button {
    background-color: #1890ff;
    color: white;
    border: none;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }

  .floating-buttons button:hover {
    background-color: #40a9ff;
  }






  table {
    border: 0;
  }

  table caption {
    font-size: 1.3em;
  }

  .responsive-table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .responsive-table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .625em;
  }

  .responsive-table td {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #ddd;
    font-size: 0.8em;
    height: 100%;
    padding: 8px;
    font-size: 14px;
  }

  .responsive-table td::before {
    content: attr(data-label);
    float: center;
    font-weight: bold;
    text-transform: uppercase;
  }

  table td:last-child {
    border-bottom: 0;
  }
}

.input-button-group {
  display: flex;
  align-items: center;
  gap: 8px;
}