@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap');

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	font-family: 'Open Sans', sans-serif;
}

.main {
	width: 100%;
	max-width: 100vw;
	transition: width 1s ease-in-out, margin-left 1s ease-in-out;
	position: relative;
	z-index: 10;
	overflow: hidden;
	background-color: #ffffff;
}

.page-with-navbar .main {
	width: calc(100%);
	transition: margin-right 1s ease-in-out;
	margin-left: 0;
}

.no-navbar .main {
	width: 100%;
}

.navbar {
	width: 290px;
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	background-color: #154c79;
	transition: transform 1s ease-in-out;
	z-index: 100;
	transform: translateX(-100%);
}

.navbar-visible {
	transform: translateX(0);
	transition: transform 1s ease-in-out; 
}

.pages {
	justify-content: center;
	align-items: center;
	transition: margin-left 1s ease-in-out;
}

.page-with-navbar {
	margin-left: 290px;
	transition: margin-left 1s ease-in-out;
	z-index: 1;
}




@media only screen and (max-width: 768px) {
	.page-with-navbar .main {
		width: calc(100%);
		transition: transform 1s;
	}
}
