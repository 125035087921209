p {
    text-align: center;
}

span {
    text-align: left;
}

.row {
    margin: 3;
}

.username {
    color: #ffffff;
}

.highlight-row {
    background-color: yellow;
}

/* mobile landscape screen */
/* ต่ำกว่า 375 ใช้ class นี้ */
@media screen and (min-width: 370px) {
    .main1 {
        align-items: center;
        width: '100%';

        & aside {
            display: grid;
            grid-template-columns: 1fr;
        }
    }

    .main {
        text-wrap: break-word;
        font-Family: Arial, Helvetica, sans-serif;
        font-size:  small ;
        & .inputaside {
            grid-template-columns: 1fr 1fr;

            & div {
                margin: 5;
            }
        }

        & aside {
            display: grid;
            margin:  auto;
            margin-left: auto;
            grid-template-columns: 1fr;

            & div {
                margin: auto;
            }
        }
        
        & fix {
            align-items: center;
            margin:  auto;
            display: grid;
            grid-template-columns: 1fr;

            & div {
                margin: 0;
            }
        }

        & column2 {
            margin:  auto;
            display: grid;
            grid-template-columns: 1fr 1fr;

            & div {
                margin: 0;
            }
        }
    }
}

/* mobile landscape screen */
@media screen and (min-width: 700px) {
    .main1 {
        align-items: center;
        width: '100%';
        font-size:  medium ;

        & aside {
            display: grid;
            grid-template-columns: 1fr 1fr;
        }
    }
    .main {
        font-size: small;
        text-wrap: break-word;
        font-Family: Arial, Helvetica, sans-serif;
        margin: 0 auto;

        width: '100%';
        & aside {
            margin-left: 2rem;
            display: grid;
            grid-template-columns: 1fr 1fr;
        }
        & fix {
            align-items: center;
            margin:  auto;
            display: grid;
            grid-template-columns: 1fr ;
        }
    }
}

/* mobile screen */
@media screen and (min-width: 1200px) {
    .main1 {
        align-items: center;
        width: '100%';

        & aside {
            display: grid;
            grid-template-columns: 1fr 1fr;
            align-items: center;
        }
    }

    .main2 {
        align-items: center;
        width: '100%';

        & aside {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-areas:
                "a a "
                "a a ";

        }
    }

    .main3 {
        align-items: center;
        width: '100%';
        & aside {
            display: grid;
            grid-template-columns: 1fr;

        }
    }

    .main {
        width: '100%';
        font-size: large;

        font-Family: Arial, Helvetica, sans-serif;

        & aside {
            display: grid;
            /* grid-template-columns: repeat(3, 1fr); */
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-areas:
                "a a a"
                "a a a";

            & div {
                margin: 0;
                width: 100%;
            }
        }
        & fix {
            align-items: center;
           
            display: grid;
            grid-template-columns: 1fr;
        }
        & fix2 {
            align-items: center;
            
            display: grid;
            grid-template-columns: 1fr 1fr;
        }
    }

}