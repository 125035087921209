p {
    text-align: center;
}

span {
    text-align: left;
}

.row {
    padding: 3;
}

.username {
    color: #ffffff;
}

.cursor:hover {
    cursor: pointer;
}


.button-with-tooltip {
    position: relative;
    display: inline-block;
}

.tooltip-text {
    visibility: hidden;
    width: 100px;
    /* ปรับขนาดตามความยาวข้อความ */
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px;
    position: absolute;
    bottom: 100%;
    /* ให้ข้อความอยู่เหนือปุ่ม */
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
    z-index: 1;
}

.button-with-tooltip:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
}



/* mobile landscape screen */
@media screen and (min-width: 375px) {
    .main1 {
        align-items: center;
        width: '100%';

        & aside {
            display: grid;
            grid-template-columns: 1fr;
        }
    }

    .main {
        & .inputaside {
            grid-template-columns: 1fr 1fr;

            & div {
                margin: 5;
            }
        }

        & aside {
            display: grid;
            margin: 0;
            grid-template-columns: 1fr;
            text-align: left;


            & div {
                margin: 0;
                text-align: left;

            }
        }
    }

    .main2 {
        & .inputaside {
            grid-template-columns: 1fr;
            margin: 5;

            & div {
                margin: 5;
            }
        }

        & aside {
            display: grid;
            margin: 0;
            grid-template-columns: 1fr;

            & div {
                margin: 5;
            }
        }
    }

    .main4 {
        & .inputaside {
            grid-template-columns: 1fr 1fr;

            & div {
                margin: 5;
            }
        }

        & aside {
            display: grid;
            margin: 0;
            grid-template-columns: 1fr;

            & div {
                margin: 0;
            }
        }
    }
}

/* mobile landscape screen */
@media screen and (min-width: 680px) {
    .main1 {
        align-items: center;
        width: '100%';

        & aside {
            display: grid;
            grid-template-columns: 1fr 1fr;
        }
    }

    /* .main6 {
        align-items: center;
        width: '100%';

        & aside {
            display: grid;
            grid-template-columns: 1fr 1fr;
            align-items: center;

            & div {
                margin: 5;
            }
        }
    } */

    .main {
        width: '100%';

        & aside {
            margin-left: 2rem;
            display: grid;
            grid-template-columns: 1fr 1fr;
        }
    }
}

/* mobile screen */
@media screen and (min-width: 1200px) {
    .main1 {
        align-items: center;
        width: '100%';

        & aside {
            display: grid;
            grid-template-columns: 1fr 1fr;
            align-items: center;
        }
    }

    .main6 {
        display: flex;
        align-items: center;
        width: 100%;

        & aside {
            display: grid;
            grid-template-columns: 1fr 1fr;
            align-items: center;
        }
    }

    .main2 {
        align-items: center;
        width: 100%;

        & aside {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-areas:
                "a a "
                "a a ";

        }

    }

    .main4 {
        & .inputaside {
            grid-template-columns: 1fr 1fr;

            & div {
                margin: 5;
            }
        }

        & aside {
            display: grid;
            margin: 0;
            grid-template-columns: 1fr 1fr;

            div {
                margin: 5;
            }

        }
    }

    .main3 {
        align-items: center;
        width: '100%';

        & aside {
            display: grid;
            grid-template-columns: 1fr;

            & div {
                margin: 0;
                width: 100%;
            }

        }
    }

    .main {
        width: '100%';

        & aside {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-areas:
                "a a a"
                "a a a";

            & div {
                margin: 0;
                width: 100%;
            }
        }
    }

    .mains {
        width: '100%';

        & aside {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;

            & div {
                margin: 10;
                width: 100%;
            }
        }
    }
}