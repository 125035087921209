nav {
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 290px;
	height: 100vh;
	background-color:
		#154c79;
	/* padding: 1rem 1rem 1rem 1rem; */
	transition: transform 1s;
	z-index: 1000;
}

.scollheight {
	overflow-y: visible;
	overflow-x: hidden;
	top: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 290px;
	height: 100vh;
	background-color:
		#154c79;
	padding: 2rem 1rem 1rem 1rem;
	transition: transform 1s;
}

.navbar {
	transform: translateX(-100%);
}

.nav-content {
	display: flex;
	flex-direction: column;
	height: 100vh;

}

.logout-container {
	margin-top: auto;
	padding-bottom: 20px;
}

.nav-btn {
	position: absolute;
	transform: translateX(95%);
	right: 0px;
	top: 12px;
	width: 40px;
	height: 60px;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #154c79;
	outline: none;
	border: none;
	font-size: 1rem;
	padding: 5px 10px;
	cursor: pointer;
	color: #fff;
	z-index: 10000;
}

.logo {
	display: block;
	width: 70%;
	margin: auto;
}



.logo img {
	display: block;
	width: 100%;
	height: 100%;
}

.nav-link {
	display: flex;
	align-items: center;
	color: #fff;
	text-decoration: none;
	padding: 10px 30px;
	border-radius: 5px;
	margin-bottom: 5px;
	position: relative;
}


.nav-link1 {
	display: flex;
	align-items: center;
	color: #fff;
	width: 100%;
	text-decoration: none;
	padding: 10px 1rem;
	border-radius: 5px;
	margin-bottom: 5px;
	position: relative;
}

.menu-bold .ant-menu-item {
	font-weight: bold;
}

.submenu {
	display: none;
	position: absolute;
	top: 100%;
	left: 0;
	background-color: #f9f9f9;
	padding: 10px;
	border: 1px solid #ddd;
}

.submenu-link {
	display: block;
	padding: 5px;
	color: black;
}

.nav-link:hover {
	background-color: #f9f9f9;
	color: #4e4e4e;
}

.nav-link span {
	margin-left: 10px;
}

.nav-link1 span {
	margin-left: 10px;
}

.nav-top {
	margin-top: 2rem;
}


.mobile-nav-btn {
	color: #fff;
	outline: none;
	border: none;
	margin: 0 10px;
}

