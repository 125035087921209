.button-with-tooltip {
    position: relative;
    display: inline-block;
    text-align: center;
}

.tooltip-text {
    position: absolute;
    top: -25px;
    left: 50%;
    transform: translateX(-50%);
    background-color: black;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    white-space: nowrap;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s;
}

.button-with-tooltip:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
}